import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Looks from "../components/looks"
import { H1 } from "../components/commons"

const Section = styled.section`
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;
`

function IndexPage({ data }) {
  const looks = data.prismic.looks.edges
  return (
    <Layout>
      <SEO title="everyday looks" />
      <Section>
        <H1>everyday looks</H1>
        <Looks looks={looks} />
      </Section>
    </Layout>
  )
}

export default IndexPage

export const IndexQuery = graphql`
  query Looks {
    prismic {
      looks: allLooks(sortBy: meta_firstPublicationDate_DESC) {
        edges {
          node {
            _meta {
              uid
              firstPublicationDate
            }
            clothing {
              brand {
                ... on PRISMIC_Brand {
                  name
                  _linkType
                }
              }
              category {
                ... on PRISMIC_Category {
                  name
                  _linkType
                }
              }
            }
            author {
              ... on PRISMIC_Author {
                username
                website {
                  ... on PRISMIC__ExternalLink {
                    url
                  }
                }
              }
            }
            title
            image
            color
          }
        }
      }
    }
  }
`
